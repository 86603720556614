import request from './request';
import axios from 'axios';
import { getToken } from '@/utils/token';
import { message } from 'antd';
import util from '@/utils/util';

const http = {
  request(config) {
    request(config);
  },

  post(url, data, loadingType = '') {
    return request({
      url,
      method: 'post',
      data,
      loading: loadingType
    });
  },
  postUrl(url, data, loadingType = '') {
    return request({
      url,
      method: 'post',
      params: data,
      loading: loadingType
    });
  },

  get(url, params, loadingType = '') {
    return request({
      url,
      method: 'get',
      params,
      loading: loadingType
    });
  },

  put(url, data, loadingType = '') {
    return request({
      url,
      method: 'put',
      data,
      loading: loadingType
    });
  },

  delete(url, id, data, loadingType = '') {
    return request({
      url,
      method: 'delete',
      data,
      params: id,
      loading: loadingType
    });
  },
  delete_two(url, loadingType = '') {
    return request({
      url,
      method: 'delete',
      loading: loadingType
    });
  },
  deletePath(url, loadingType = '') {
    return request({
      url,
      method: 'delete',
      loading: loadingType
    });
  },

  exportFileTemp({ url, data, params, loadingType = '', method = 'post' }) {
    return request({
      url,
      method,
      data,
      params,
      responseType: 'blob',
      loading: loadingType
    });
  },
  resetExportExelTemp(url, data, loadingType = '') {
    axios({
      url,
      method: 'post',
      data,
      headers: {
        System: 'CNI',
        language: util.convertLanguage(),
        Authorization: 'Bearer ' + getToken()
      },
      responseType: 'blob',
      loading: loadingType
    })
      .then(res => {
        // blob下载文件时后端抛出异常，并返回json错误信息前端捕获信息并提示
        if (res.status === 200 && res.config.responseType === 'blob') {
          if (res.headers['content-type'].includes('json')) {
            let data = res.data;
            let fileReader = new FileReader();
            fileReader.readAsText(data);
            fileReader.onload = function (result) {
              let jsonData = JSON.parse(result.target.result);
              message.error(jsonData.msg);
            };
          }
        }
        if (res.status === 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers['content-disposition'].split('=')[1].substr(7)
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(data => {});
  },
  resetExportExelTempGet(url, params, loadingType = '') {
    axios({
      url,
      method: 'get',
      params,
      headers: {
        System: 'CNI',
        language: util.convertLanguage(),
        Authorization: 'Bearer ' + getToken()
      },
      responseType: 'blob',
      loading: loadingType
    })
      .then(res => {
        // blob下载文件时后端抛出异常，并返回json错误信息前端捕获信息并提示
        if (res.status === 200 && res.config.responseType === 'blob') {
          if (res.headers['content-type'].includes('json')) {
            let data = res.data;
            let fileReader = new FileReader();
            fileReader.readAsText(data);
            fileReader.onload = function (result) {
              let jsonData = JSON.parse(result.target.result);
              message.error(jsonData.msg);
            };
          }
        }
        if (res.status === 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers['content-disposition'].split('=')[1].substr(7)
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(data => {});
  },
  formSubmit(url, data, rateParmas, loadingType = '') {
    return request({
      url,
      method: 'post',
      data,
      timeout: 1000 * 60 * 60 * 5,
      headers: {
        System: 'CNI',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      loading: loadingType,
      onUploadProgress: function (e) {
        if (rateParmas && e.lengthComputable) {
          rateParmas.event = e;
          let handlerRate = ((e.loaded / e.total) * 100).toFixed(2) + '%';
          let rate = e.loaded / e.total; //已上传的比例
          if (rate < 1) {
            rateParmas.rate = handlerRate;
          }
        }
      }
    });
  }
};

export default http;
