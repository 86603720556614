import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Drawer, ConfigProvider, message } from 'antd';
import { withTranslation } from 'react-i18next';
import './App.css';
import AlphaESSSider from '@/containers/AlphaESSSider';

import AlphaESSBreadcrumb from '@/containers/AlphaESSBreadcrumb';
import { getToken, setToken, removeToken } from '@/utils/token';
import { getUserInfo, getAccessMenu, getStationType } from 'api';
import { updateUserInfo } from '@/actions/user';
import {
  updateAccessMenu,
  updateSysRole,
  updateStationType,
  updateDepotId,
  setProjectName,
  updateShowChangeStation
} from '@/actions/app';
import util from '@/utils/util';
import AppRouters from '@/routers/AppRouters';
import constantMenu from '@/constantMenu';
const { Content } = Layout;
@withTranslation(['common'])
class App extends Component {
  state = {
    collapsed: localStorage.getItem('dataType') == 'local' || false,
    responsive: false,
    navTabShow: false,
    navTabTop: 0.7,
    openDrawer: false,
    currentLanguage: util.convertLanguageAntd(localStorage.getItem('language'))
  };
  constructor(props) {
    super(props);
    let errorOperation = document.getElementById('error-operation');
    if (errorOperation) {
      errorOperation.parentNode.removeChild(errorOperation);
    }
  }

  componentDidMount() {
    this.initAppData(); //数据初始化完后再触发一次render
    this.getClientWidth(); //判断屏幕尺寸再触发一次render(不需要可去掉)
    window.onresize = () => {
      this.getClientWidth();
    };
    setTimeout(() => {
      let loading = document.getElementById('StartLoading');
      loading && document.body.removeChild(loading);
    }, 200);
  }
  componentWillUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.initChildData(nextProps);
    }
    if (this.props.language !== nextProps.language) {
      this.setState({
        currentLanguage: util.convertLanguageAntd(nextProps.language)
      });
    }
  }
  filterBMSMenuByUserRole(role, bmsMenu) {
    for (let i = 0; i < bmsMenu.length; i++) {
      if (bmsMenu[i].meta.roles.includes(role)) {
        this.filterBMSMenuByUserRole(role, bmsMenu[i].children);
      } else {
        bmsMenu[i].splice(i, 1);
      }
    }
    return bmsMenu;
  }
  getClientWidth = () => {
    // 获取当前浏览器宽度并设置responsive管理响应式
    const clientWidth = document.body.clientWidth;
    this.setState({
      responsive: clientWidth <= 992,
      collapsed:
        clientWidth <= 992 || localStorage.getItem('dataType') == 'local',
      clientWidth: clientWidth
    });
    if (clientWidth < 576) {
      this.setState({
        navTabTop: 1.6
      });
      return;
    }
    if (clientWidth < 768) {
      this.setState({
        navTabTop: 1.29
      });
      return;
    }
    if (clientWidth >= 768) {
      this.setState({
        navTabTop: 0.76
      });
      return;
    }
  };
  toggle = () => {
    if (this.state.responsive) {
      this.setState(
        {
          openDrawer: !this.state.openDrawer
        },
        () => {}
      );
    } else {
    }

    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  toggleNavTab = () => {
    this.setState({ navTabShow: !this.state.navTabShow });
  };
  onClose = () => {
    this.setState({
      openDrawer: false,
      collapsed: !this.state.collapsed
    });
  };
  /* 初始化系统的基本信息（用户信息和用户菜单） */
  initAppData = async () => {
    /* 获取当前用户的Token，如果没有获取成功，则需要跳转至登录页 */
    const { t } = this.props;
    let token = '';
    if (
      decodeURIComponent(
        util.getUrlParamValue(this.props.location.search, ['token']).token
      ) === 'null'
    ) {
      token = getToken();
    } else {
      token = decodeURIComponent(
        util.getUrlParamValue(this.props.location.search, ['token']).token
      );
      setToken(token);
    }
    if (
      decodeURIComponent(
        util.getUrlParamValue(this.props.location.search, ['depotId']).depotId
      ) !== 'null'
    ) {
      this.props.updateDepotId(
        decodeURIComponent(
          util.getUrlParamValue(this.props.location.search, ['depotId']).depotId
        )
      );
      localStorage.setItem(
        'depotId',
        decodeURIComponent(
          util.getUrlParamValue(this.props.location.search, ['depotId']).depotId
        )
      );
    }
    if (
      decodeURIComponent(
        util.getUrlParamValue(this.props.location.search, ['depotName'])
          .depotName
      ) !== 'null'
    ) {
      this.props.setProjectName(
        decodeURIComponent(
          util.getUrlParamValue(this.props.location.search, ['depotName'])
            .depotName
        )
      );
      localStorage.setItem(
        'projectName',
        decodeURIComponent(
          util.getUrlParamValue(this.props.location.search, ['depotName'])
            .depotName
        )
      );
    }

    if (token === null || token === '' || token === 'null') {
      this.props.history.push('/login');
      return;
    }

    let stationTypeRes = await getStationType();
    if (localStorage.getItem('stationType') === null) {
      if (
        stationTypeRes &&
        stationTypeRes.code === 200 &&
        stationTypeRes.data
      ) {
        if (
          stationTypeRes.data.stationType === 0 ||
          stationTypeRes.data.stationType === 2
        ) {
          localStorage.setItem('stationType', '0');
        } else {
          localStorage.setItem('stationType', '1');
        }
        if (stationTypeRes.data.stationType === 2) {
          this.props.updateShowChangeStation('yes');
        } else {
          this.props.updateShowChangeStation('no');
        }
      }
    }

    let infoRes = await getUserInfo();
    if (infoRes.data.roles && !infoRes.data.roles.includes('customer')) {
      if (
        infoRes.data.systemCode.length === 1 &&
        infoRes.data.systemCode.includes('ci')
      ) {
        localStorage.setItem('showSysButtonGroup', '0');
        localStorage.setItem('currentSystemType', 'ci');
      } else if (
        infoRes.data.systemCode.includes('ci') &&
        infoRes.data.systemCode.includes('alphacloud')
      ) {
        localStorage.setItem('showSysButtonGroup', '1');
        localStorage.setItem('currentSystemType', 'ci');
      } else {
        localStorage.setItem('showSysButtonGroup', '0');
        localStorage.setItem('currentSystemType', 'ci');
      }
    }
    let menuRes;
    let tempMenu = await getAccessMenu({
      systemId: '4RCLmooUALfD',
      stationType: localStorage.getItem('stationType')
        ? parseInt(localStorage.getItem('stationType'))
        : 0
    });

    if (
      !tempMenu ||
      !tempMenu.data ||
      !tempMenu.data.menu ||
      !tempMenu.data.menu.length
    ) {
      message.error(t('common.noMenuTips'), 5);
      removeToken();
      return;
    }
    menuRes = util.handleMenu(tempMenu.data.menu, 1);
    let userInfo = {
      username: infoRes.data.username,
      email: infoRes.data.email,
      stationType: localStorage.getItem('stationType')
        ? parseInt(localStorage.getItem('stationType'))
        : 0,
      roles: infoRes.data.roles,
      userId: infoRes.data.userId,
      timeZone: infoRes.data.timeZone
    };
    this.props.updateSysRole(infoRes.data.roles);
    this.props.updateStationType(
      localStorage.getItem('stationType')
        ? parseInt(localStorage.getItem('stationType'))
        : 0
    );
    menuRes.push(...constantMenu);
    let openAccessMenu = util.openAccessMenu(menuRes); //所有的菜单
    let moduleList = menuRes.filter(item => {
      return item.leftMenu;
    });
    let currentModule = moduleList?.[0]?.name; //第一个模块
    let moduleMenu = moduleList;
    this.props.updateAccessMenu({
      currentModule: currentModule,
      accessMenu: menuRes,
      openAccessMenu: openAccessMenu,
      moduleMenu: moduleMenu,
      moduleList: moduleList
    });
    this.props.updateUserInfo(userInfo);
    if (this.state.responsive) {
      return;
    }
    if (
      decodeURIComponent(
        util.getUrlParamValue(this.props.location.search, ['depotId']).depotId
      ) !== 'null'
    ) {
      this.props.history.push('/app/mainSystem/mainSystemInterface');
    }
    this.initChildData(this.props);
  };
  initChildData(props) {
    this.refs['AlphaESSSider'].initMenu(props.location.pathname);
  }
  render() {
    let self = this;
    return (
      <Layout>
        {this.state.responsive ? (
          <Drawer
            visible={this.state.openDrawer}
            placement={'left'}
            width={248}
            closable={false}
            onClose={this.onClose}
            className="responsive-drawer"
          >
            <AlphaESSSider
              ref={'AlphaESSSider'}
              responsive={this.state.responsive}
              collapsed={this.state.collapsed}
              pathname={this.props.location.pathname}
              toggle={this.toggle}
            ></AlphaESSSider>
          </Drawer>
        ) : (
          <AlphaESSSider
            ref={'AlphaESSSider'}
            responsive={this.state.responsive}
            collapsed={this.state.collapsed}
            pathname={this.props.location.pathname}
            toggle={this.toggle}
          ></AlphaESSSider>
        )}

        <Layout
          style={{
            background: 'var(--main-bg-color)',
            paddingTop: 0
          }}
        >
          <AlphaESSBreadcrumb
            collapsed={this.state.collapsed}
            responsive={this.state.responsive}
            toggle={this.toggle}
          />
          <ConfigProvider locale={this.state.currentLanguage}>
            <Content
              style={{
                width:
                  self.props.location.pathname === '/app/home' ? '100%' : '97%',
                margin: '0px auto'
              }}
            >
              <AppRouters />
            </Content>
          </ConfigProvider>
        </Layout>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  let { name } = state.user;
  let { language } = state.app;
  return { name, language };
};
const mapDispatchToProps = dispatch => {
  return {
    updateUserInfo: info => {
      dispatch(updateUserInfo(info));
    },
    updateAccessMenu: accessMenu => {
      dispatch(updateAccessMenu(accessMenu));
    },
    updateSysRole: role => {
      dispatch(updateSysRole(role));
    },
    updateStationType: stationType => {
      dispatch(updateStationType(stationType));
    },
    updateDepotId: depotId => {
      dispatch(updateDepotId(depotId));
    },
    setProjectName: projectName => {
      dispatch(setProjectName(projectName));
    },
    updateShowChangeStation: showChangeStation => {
      dispatch(updateShowChangeStation(showChangeStation));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
