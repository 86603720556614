/*
 * @Description: 面包屑
 * @Author: Summer.Chen
 * @Date: 2024-01-26 15:12:03
 * @LastEditTime: 2024-11-28 13:45:31
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, Select, Switch, Radio, Space } from 'antd';
import MenuToRouter from '@/menuMapToRouter';
import MenuToBmsRouter from '@/menuMapToRouterBms';
import util from '@/utils/util';
import { getToken } from '@/utils/token';
import {
  updateAccessMenu,
  updateDepotId,
  setProjectName,
  updateTheme
} from '@/actions/app';
import { logout } from 'api';
import { withTranslation } from 'react-i18next';
import { updateSysSn, updateSysList } from '@/actions/app';
import { injectUnMount } from '@/utils/handleUnMount';
import Icon, {
  LogoutOutlined,
  AlignLeftOutlined,
  AlignRightOutlined
} from '@ant-design/icons';
import LangSelect from '@/components/LangSelect';
import { theme } from '@/theme';
import { removeToken } from '@/utils/token';
import { ICToBMSKeyMapping } from '@/i18n/ICToBMSKeyMapping';
@withTranslation(['leftMenu'])
@injectUnMount
class AlphaESSBreadcrumb extends React.PureComponent {
  allSystem = [];
  constructor(props) {
    super(props);
    this.state = {
      map: [], //地图列表
      seconds: new Date().getTime(),
      sysSn: '',
      theme: 'dark',
      currentSysType: localStorage.getItem('currentSystemType') || 'ci'
    };
  }
  // 生成面包屑
  buildBreadcrumb(name) {
    const { stationType } = this.props;
    let newMap = [];
    if (name) {
      let parents = util.getParentMenusByName(this.props.openAccessMenu, name);
      for (let p of parents) {
        newMap.push({
          title:
            stationType === 1 && ICToBMSKeyMapping[p.languageKey]
              ? ICToBMSKeyMapping[p.languageKey]
              : p.languageKey,
          name: p.name
        });
      }
      this.setState({
        map: newMap
      });
    }
  }
  componentDidUpdate(nextProps) {
    let name;
    if (
      localStorage.getItem('stationType') == 0 ||
      localStorage.getItem('stationType') == 2 ||
      localStorage.getItem('stationType') == undefined
    ) {
      name = Object.keys(MenuToRouter).find(
        key => MenuToRouter[key] === nextProps.location.pathname
      );
    } else {
      name = Object.keys(MenuToBmsRouter).find(
        key => MenuToBmsRouter[key] === nextProps.location.pathname
      );
    }

    setTimeout(() => {
      this.buildBreadcrumb(name);
    }, 0);
  }
  renderSelect(self) {
    const { Option } = Select;
    return (
      <Select
        value={self.state.sysSn}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >=
            0
          );
        }}
        filtersort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        onChange={value => {
          this.props.updateSysSn(value);
        }}
        style={{ minWidth: '3rem' }}
      >
        {self.allSystem.length > 0 &&
          self.allSystem.map((item, index) => (
            <Option key={index} value={item.essSn}>
              {(item.essSn ? item.essSn : '') +
                (item.sysName ? '(' + item.sysName + ')' : '')}
            </Option>
          ))}
      </Select>
    );
  }
  modelChange(value) {
    let model = value ? 'dark' : 'light';
    window.less.modifyVars({
      ...theme[model]
    });
    this.setState({
      theme: model
    });
    this.setImgTheme(theme[model]);
    localStorage.setItem('theme', model);
    this.props.updateTheme(model);
  }
  isImage(str) {
    var regExp = /\(([^)]+)\)/;
    //  \(: 匹配左括号
    // (: 开始捕获组
    // [^)]+: 匹配一个或多个非)字符
    // ): 结束捕获组
    // \): 匹配右括号
    var matches = regExp.exec(str);
    var reg = /.(png|jpg|gif|jpeg|svg)$/;
    return reg.test(matches ? matches[1] : null);
  }

  setImgTheme(theme) {
    Object.keys(theme).forEach(key => {
      if (this.isImage(theme[key].toLowerCase())) {
        document.body.style.setProperty(key.replace('@', '--'), theme[key]);
      }
    });
  }
  // 退出登录
  logoutMethod = () => {
    if (this.props.dataType === 'local') {
      removeToken();
      // 退出一定要清理moduleMenu不然下次进入其他角色的用户菜单权限变更，会报错页面渲染不了
      this.props.updateAccessMenu({
        currentModule: '',
        accessMenu: [],
        openAccessMenu: [],
        moduleMenu: [],
        moduleList: []
      });
      this.props.updateDepotId('');
      this.props.setProjectName('');
      localStorage.clear();
      this.props.history.push('/login');
      return;
    }
    logout().then(res => {
      removeToken();
      // 退出一定要清理moduleMenu不然下次进入其他角色的用户菜单权限变更，会报错页面渲染不了
      this.props.updateAccessMenu({
        currentModule: '',
        accessMenu: [],
        openAccessMenu: [],
        moduleMenu: [],
        moduleList: []
      });
      this.props.updateDepotId('');
      this.props.setProjectName('');
      localStorage.clear();
      this.props.history.push('/login');
    });
  };
  initTheme() {
    try {
      let themeOrigin = '';
      if (this.props.theme) {
        themeOrigin = this.props.theme;
      } else {
        themeOrigin = localStorage.getItem('theme')
          ? localStorage.getItem('theme')
          : 'light';
      }
      window.less.modifyVars({
        ...theme[themeOrigin]
      });
      this.setImgTheme(theme[themeOrigin]);
      this.setState({
        theme: themeOrigin
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.initTheme();
  }
  /**
   * @Author: Wayne Hu
   * @description: 处理切换系统按钮点击事件
   */
  handleButtonClick = e => {
    let targetUrl = '';
    if (window.location.host.indexOf('huidu') > -1) {
      targetUrl = 'https://huidu.alphaess.com';
    } else if (window.location.host.indexOf('cni.alphaess.com') > -1) {
      targetUrl = 'https://cloud.alphaess.com';
    } else {
      targetUrl = 'http://192.168.8.64:10042';
    }
    const token = getToken();
    if (e.target.value === 'alphaCloud') {
      const systemType = 'alphaCloud';
      // 构建带参数的URL
      const urlWithParams = `${targetUrl}/index?token=${encodeURIComponent(
        token
      )}&systemType=${encodeURIComponent(systemType)}`;
      // 打开新窗口
      window.open(urlWithParams);
    }
    if (e.target.value === 'all') {
      const systemType = 'all';
      // 构建带参数的URL
      const urlWithParams = `${targetUrl}/unifiedDashboard?token=${encodeURIComponent(
        token
      )}&systemType=${encodeURIComponent(systemType)}`;
      window.open(urlWithParams);
    }
  };

  // 组件渲染函数
  render() {
    const { t } = this.props;
    let self = this;
    return (
      <div
        style={{
          height: '50px',
          lineHeight: '50px',
          padding: '0px 25px',
          background: 'var(--header-bg-color)',
          borderBottomRightRadius: '10px',
          marginBottom:
            self.props.location.pathname === '/app/home' ? '0px' : '16px',
          zIndex: 1
        }}
      >
        {this.props.responsive ? (
          <Icon
            style={{
              color: 'var(--collapsed-icon-color)',
              fontSize: '0.3rem',
              padding: ' 10px 10px 10px 24px',
              cursor: 'pointer'
            }}
            onClick={() => {
              self.props.toggle();
            }}
            component={
              self.props.collapsed ? AlignRightOutlined : AlignLeftOutlined
            }
          />
        ) : (
          <Space>
            <Breadcrumb style={{ display: 'inline-block' }}>
              {this.state.map.map(item => (
                <Breadcrumb.Item key={item.name}>
                  {item.path ? (
                    <Link to={item.path}>
                      <span>{t(item.title)}</span>
                    </Link>
                  ) : (
                    <span>
                      {t(item.title)}
                      {item.icon}
                    </span>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {localStorage.getItem('showSysButtonGroup') === '1' ? (
              <Radio.Group
                value={this.state.currentSysType}
                size="small"
                optionType="button"
                buttonStyle="solid"
                onChange={this.handleButtonClick}
              >
                <Radio.Button value="all">
                  {t('common:common.All')}
                </Radio.Button>
                <Radio.Button value="alphaCloud">
                  {t('common:common.Residential')}
                </Radio.Button>
                <Radio.Button value="ci">
                  {t('common:common.Commercial')}
                </Radio.Button>
              </Radio.Group>
            ) : null}
          </Space>
        )}

        <div
          style={{
            float: 'right'
          }}
        >
          {this.props.dataType === 'local' ? null : (
            <label
              style={{
                marginRight: '15px',
                color: 'var(--theme-color)',
                fontSize: '14px',
                fontWeight: '500'
              }}
            >
              {t('leftMenu.theme')}
            </label>
          )}

          {this.props.dataType === 'local' ? null : (
            <Switch
              defaultChecked={this.props.theme === 'light' ? false : true}
              onChange={this.modelChange.bind(this)}
            ></Switch>
          )}
          <LangSelect
            langFromUrl={new URLSearchParams(
              window.location.hash.split('?')[1]
            ).get('langCode')}
          />
          <LogoutOutlined
            style={{
              color: this.props.theme === 'light' ? '#000' : '#fff',
              fontSize: '16px'
            }}
            title={t('leftMenu.logout')}
            onClick={this.logoutMethod}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { spinLoading, dataType, stationType } = state.app;
  return {
    spinLoading: spinLoading,
    openAccessMenu: state.app.openAccessMenu,
    sysSn: state.app.sysSn,
    username: state.user.username,
    isRefresh: state.app.isRefresh,
    projectName: state.app.projectName || localStorage.getItem('projectName'),
    theme: state.app.theme,
    dataType,
    stationType
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateSysSn: sysSn => {
      dispatch(updateSysSn(sysSn));
    },
    updateSysList: isRefresh => {
      dispatch(updateSysList(isRefresh));
    },
    updateAccessMenu: accessMenu => {
      dispatch(updateAccessMenu(accessMenu));
    },
    updateDepotId: depotId => {
      dispatch(updateDepotId(depotId));
    },
    setProjectName: projectName => {
      dispatch(setProjectName(projectName));
    },
    updateTheme: theme => {
      dispatch(updateTheme(theme));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlphaESSBreadcrumb)
);
