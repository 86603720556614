/*
 * @Description: app端注册成功后，跳转的激活页面
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/04/12 10:05:54
 * @LastEditTime: 2024/11/07 10:37:19
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, message } from 'antd';
import { connect } from 'react-redux';
import LangSelect from '@/components/LangSelect';
import '@/style/login.less';
import beforeLogin from '@/services/beforeLogin.js';
import { theme } from '@/theme';
import { updateLanguage } from '@/actions/app';
import { dataCenterList } from '../constants/dataCenter.js';

@withTranslation(['leftMenu', 'registerPage'])
// 注册页面对应的组件
class RegisterSuccess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      licenseNo: '',
      sysSn: '',
      userId: '',
      userType: '',
      key: '',
      activeSuccess: false
    };
  }
  curtomerHeight() {
    document.querySelector('.curtomer-style-register').style.height =
      window.innerHeight;
  }

  // 获取URL参数
  getQueryVariable = variable => {
    if (this.props.location.search.length > 0) {
      let queryString = this.props.location.search.substring(1);
      let queryArr = queryString.split('&');
      for (let i = 0; i < queryArr.length; i++) {
        var paraArr = queryArr[i].split('=');
        if (paraArr[0] === variable) {
          return paraArr[1];
        }
      }
      return '';
    } else {
      return '';
    }
  };

  UNSAFE_componentWillMount() {
    document.getElementsByTagName('body')[0].style.background =
      "url('/static/images/login_bg.png') center center / cover no-repeat";
  }

  componentDidMount() {
    const { t } = this.props;
    let lang = this.getQueryVariable('Lang').substring(0, 2).toLowerCase();
    updateLanguage({ language: lang });
    let licenseNo = this.getQueryVariable('LicenseNo');
    let sysSn = this.getQueryVariable('SysSn');
    let userId = this.getQueryVariable('UserName');
    let userType = this.getQueryVariable('UserType');
    let key = this.getQueryVariable('Key');
    let platformType = this.getQueryVariable('platform');
    let hostCode = this.getQueryVariable('hostCode');
    const url = dataCenterList.filter(item => item.key === hostCode);
    const currentURL = window.location.href;
    currentURL.includes('https') && localStorage.setItem('dataCenter', url[0].url);
    this.setState(
      {
        licenseNo: licenseNo,
        sysSn: sysSn,
        userId: userId,
        userType: userType,
        key: key
      },
      () => {
        if (platformType == '1') {
          beforeLogin
            .activeAccount({
              licenseNo: this.state.licenseNo,
              sysSn: this.state.sysSn,
              userId: this.state.userId,
              userType: this.state.userType,
              key: this.state.key
            })
            .then(res => {
              if (res && res.code === 200) {
                this.setState({ activeSuccess: true });
              } else {
                this.setState({ activeSuccess: false });
              }
            })
            .catch(() => {
              this.setState({ activeSuccess: false });
            });
        } else {
          message.error(t('registerPage:registerPage.paramsIsNull'));
        }
      }
    );
    this.curtomerHeight();
    let model = 'light';
    window.less.modifyVars({
      ...theme[model]
    });
  }

  render() {
    let { t } = this.props;
    return (
      <div className="curtomer-style-register register_mainBox">
        <div className="register_main">
          {/*公司logo */}
          <div className="logo flex">
            <span className="logo-content">
              <img alt="logo" src="/static/images/alpha-logo.png" />
            </span>
            <div className="switch-language">
              <LangSelect />
            </div>
          </div>
          <div className="register_body">
            <div className="register_bg">
              {this.state.activeSuccess === true ? (
                <h1 className="register_title">
                  {t('registerPage:registerPage.activeSuccess')}
                </h1>
              ) : (
                <h1 className="register_title">
                  {t('registerPage:registerPage.activing')}
                </h1>
              )}
              {this.state.activeSuccess === true ? (
                <img
                  alt="logo"
                  style={{ display: 'block', margin: '5px auto' }}
                  src="/static/loading.gif"
                />
              ) : (
                <img
                  alt="logo"
                  style={{ display: 'block', margin: '5px auto' }}
                  src="/static/svg/register-success.svg"
                />
              )}

              {this.state.activeSuccess === true ? (
                <Button
                  onClick={() => this.props.history.push('/login')}
                  type="primary"
                  className="register_btn btn-radius_25 btn_white"
                >
                  {t('registerPage:registerPage.goLoginPage')}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.user.name,
    language: state.app.language
  };
};

export default withRouter(connect(mapStateToProps)(RegisterSuccess));
